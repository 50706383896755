export const CATEGORY = Object.freeze({
  USERS: 'User',
  TEAMS: 'Teams',
  RECRUITERS: 'Recruiters',
  CANDIDATES: 'Candidates',
  LOGS: 'Logs',
  TRASH: 'Trash',
  CV_GENERATOR: 'CV Generator',
  JOBS: 'Jobs',
  SETTINGS: 'Settings',
  MAILBOX: 'Mailbox',
  CANDIDATE: 'Candidate',
  APPLICATION_FORMS: 'Application Forms',
});

export const UserPermission = Object.freeze({
  // Application Forms
  VIEW_APPLICATION_FORMS: {
    category: CATEGORY.APPLICATION_FORMS,
    label: 'View application forms',
    value: 'view_application_forms',
  },

  // Users
  VIEW_USERS: { category: CATEGORY.USERS, label: 'View users', value: 'view_users' },
  CREATE_USERS: {
    category: CATEGORY.USERS,
    label: 'Create users',
    value: 'create_users',
  },
  EDIT_USERS: { category: CATEGORY.USERS, label: 'Edit users', value: 'edit_users' },
  DELETE_USERS: {
    category: CATEGORY.USERS,
    label: 'Delete users',
    value: 'delete_users',
  },

  // Teams
  VIEW_TEAMS: { category: CATEGORY.TEAMS, label: 'View teams', value: 'view_teams' },
  CREATE_TEAMS: {
    category: CATEGORY.TEAMS,
    label: 'Create teams',
    value: 'create_teams',
  },
  EDIT_TEAMS: { category: CATEGORY.TEAMS, label: 'Edit teams', value: 'edit_teams' },
  DELETE_TEAMS: {
    category: CATEGORY.TEAMS,
    label: 'Delete teams',
    value: 'delete_teams',
  },

  // Recruiters
  VIEW_RECRUITERS: {
    category: CATEGORY.RECRUITERS,
    label: 'View recruiters',
    value: 'view_recruiters',
  },
  CREATE_RECRUITERS: {
    category: CATEGORY.RECRUITERS,
    label: 'Create recruiters',
    value: 'create_recruiters',
  },
  EDIT_RECRUITERS: {
    category: CATEGORY.RECRUITERS,
    label: 'Edit recruiters',
    value: 'edit_recruiters',
  },
  DELETE_RECRUITERS: {
    category: CATEGORY.RECRUITERS,
    label: 'Delete recruiters',
    value: 'delete_recruiters',
  },

  // Candidates
  VIEW_CANDIDATES: {
    category: CATEGORY.CANDIDATES,
    label: 'View candidates',
    value: 'view_candidates',
  },
  CREATE_CANDIDATES: {
    category: CATEGORY.CANDIDATES,
    label: 'Create candidates',
    value: 'create_candidates',
  },
  EDIT_CANDIDATES: {
    category: CATEGORY.CANDIDATES,
    label: 'Edit candidates',
    value: 'edit_candidates',
  },
  DELETE_CANDIDATES: {
    category: CATEGORY.CANDIDATES,
    label: 'Delete candidates',
    value: 'delete_candidates',
  },

  // LOGS
  VIEW_LOGS: {
    category: CATEGORY.LOGS,
    label: 'View logs',
    value: 'view_logs',
  },

  // LOGS
  VIEW_TRASH: {
    category: CATEGORY.TRASH,
    label: 'View trash',
    value: 'view_trash',
  },

  // CV-GENERATOR
  VIEW_CVS: {
    category: CATEGORY.CV_GENERATOR,
    label: 'View CVs',
    value: 'view_cvs',
  },
  CREATE_CVS: {
    category: CATEGORY.CV_GENERATOR,
    label: 'Create CV',
    value: 'create_cvs',
  },
  EDIT_CVS: {
    category: CATEGORY.CV_GENERATOR,
    label: 'Edit CV',
    value: 'edit_cvs',
  },
  DELETE_CVS: {
    category: CATEGORY.CV_GENERATOR,
    label: 'Delete CVs',
    value: 'delete_cvs',
  },

  // JOBS
  VIEW_JOBS: {
    category: CATEGORY.JOBS,
    label: 'View Jobs',
    value: 'view_jobs',
  },
  CREATE_JOBS: {
    category: CATEGORY.JOBS,
    label: 'Create Jobs',
    value: 'create_jobs',
  },
  EDIT_JOBS: {
    category: CATEGORY.JOBS,
    label: 'Edit Jobs',
    value: 'edit_jobs',
  },
  DELETE_JOBS: {
    category: CATEGORY.JOBS,
    label: 'Delete Jobs',
    value: 'delete_jobs',
  },

  // SETTINGS
  VIEW_SETTINGS: {
    category: CATEGORY.SETTINGS,
    label: 'View Settings',
    value: 'view_settings',
  },
  CREATE_SETTINGS: {
    category: CATEGORY.SETTINGS,
    label: 'Create Settings',
    value: 'create_settings',
  },
  EDIT_SETTINGS: {
    category: CATEGORY.SETTINGS,
    label: 'Edit Settings',
    value: 'edit_settings',
  },
  DELETE_SETTINGS: {
    category: CATEGORY.SETTINGS,
    label: 'Delete Settings',
    value: 'delete_settings',
  },

  // MAILBOX
  VIEW_MAILBOX: {
    category: CATEGORY.MAILBOX,
    label: 'View Mailbox',
    value: 'view_mailbox',
  },

  // CANDIDATES
  VIEW_PROFILES: {
    category: CATEGORY.CANDIDATE,
    label: 'View Profiles',
    value: 'view_profiles',
  },
  MANAGE_PROFILES: {
    category: CATEGORY.CANDIDATE,
    label: 'Manage Profiles',
    value: 'manage_profiles',
  },
});

export const userPermissionList = [...Object.values(UserPermission)];

export const getPermissionListByCategory = (category) => {
  return userPermissionList.filter((el) => el.category === category);
};
