import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/auth/authService';
import Gate from '@/gate';
import jwt from '@/http/requests/auth/jwt';
import { UserPermission } from '@/enums/UserPermission';
import { UserRole } from '@/enums/UserRole';
import { disableLoader, setPageTitle } from '@/helpers';

const gate = new Gate();

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('@/layouts/main/Main'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: () => {
            return auth.getDefaultLoginRoute();
          },
        },

        // USERS
        {
          path: '/users',
          name: 'users-list',
          component: () => import('@/views/pages/users/user-list/UserList'),
          meta: {
            title: 'Users',
            permissions: [UserPermission.VIEW_USERS.value],
            authRequired: true,
          },
        },
        {
          path: '/users/create',
          name: 'users-create',
          component: () => import('@/views/pages/users/user-create/UserCreate'),
          meta: {
            title: 'Create user',
            permissions: [UserPermission.CREATE_USERS.value],
            authRequired: true,
          },
        },
        {
          path: '/users/:id/edit',
          name: 'users-edit',
          component: () => import('@/views/pages/users/user-edit/UserEdit'),
          meta: {
            title: 'Edit user',
            permissions: [UserPermission.EDIT_USERS.value],
            authRequired: true,
          },
        },

        // USER PROFILE
        {
          path: '/user-profile',
          name: 'profile',
          component: () => import('@/views/pages/user-settings/UserSettings'),
          meta: {
            title: 'Profile',
            authRequired: true,
          },
        },

        // TEAMS
        {
          path: '/teams',
          name: 'teams-list',
          component: () => import('@/views/pages/teams/team-list/TeamList'),
          meta: {
            title: 'Teams',
            permissions: [UserPermission.VIEW_TEAMS.value],
            authRequired: true,
          },
        },
        {
          path: '/teams/create',
          name: 'teams-create',
          component: () => import('@/views/pages/teams/TeamCreate'),
          meta: {
            title: 'Create team',
            permissions: [UserPermission.CREATE_TEAMS.value],
            authRequired: true,
          },
        },
        {
          path: '/teams/:id/edit',
          name: 'teams-edit',
          component: () => import('@/views/pages/teams/TeamEdit'),
          meta: {
            title: 'Edit team',
            permissions: [UserPermission.EDIT_TEAMS.value],
            authRequired: true,
          },
        },

        // RECRUITERS
        {
          path: '/recruiters',
          name: 'recruiters-list',
          component: () => import('@/views/pages/recruiters/recruiter-list/RecruiterList'),
          meta: {
            title: 'Recruiters',
            permissions: [UserPermission.VIEW_RECRUITERS.value],
            authRequired: true,
          },
        },
        {
          path: '/recruiters/create',
          name: 'recruiters-create',
          component: () => import('@/views/pages/recruiters/RecruiterCreate'),
          meta: {
            title: 'Create recruiter',
            permissions: [UserPermission.CREATE_RECRUITERS.value],
            authRequired: true,
          },
        },
        {
          path: '/recruiters/:id/edit',
          name: 'recruiters-edit',
          component: () => import('@/views/pages/recruiters/RecruiterEdit'),
          meta: {
            title: 'Edit recruiter',
            permissions: [UserPermission.EDIT_RECRUITERS.value],
            authRequired: true,
          },
        },

        // CANDIDATES
        {
          path: '/candidates',
          name: 'candidates',
          component: () => import('@/views/pages/candidates/candidate-table/CandidateTable'),
          meta: {
            title: 'Candidates',
            permissions: [UserPermission.VIEW_CANDIDATES.value],
            authRequired: true,
          },
        },
        {
          path: '/candidates/list',
          name: 'candidates-list',
          component: () => import('@/views/pages/candidates/candidate-list/CandidateList'),
          meta: {
            title: 'Candidates list',
            permissions: [UserPermission.VIEW_CANDIDATES.value],
            authRequired: true,
          },
        },
        {
          path: '/candidates/create',
          name: 'candidates-create',
          component: () => import('@/views/pages/candidates/CandidateCreate'),
          meta: {
            title: 'Create candidate',
            permissions: [UserPermission.CREATE_CANDIDATES.value],
            authRequired: true,
          },
        },
        {
          path: '/candidates/:id/edit',
          name: 'candidates-edit',
          component: () => import('@/views/pages/candidates/CandidateEdit'),
          meta: {
            title: 'Edit candidate',
            permissions: [UserPermission.EDIT_CANDIDATES.value],
            authRequired: true,
          },
        },
        // SEARCH HISTORY
        {
          path: '/candidates/search-history',
          name: 'search-history',
          component: () => import('@/views/pages/search-history/SearchHistoryList'),
          meta: {
            title: 'SearchHistory',
            permissions: [UserPermission.VIEW_CANDIDATES.value],
            authRequired: true,
          },
        },
        // ACTIVITY LOGS
        {
          path: '/activity-logs',
          name: 'activity-log-list',
          component: () => import('@/views/pages/activity-logs/ActivityLogList'),
          meta: {
            title: 'Activity Logs',
            permissions: [UserPermission.VIEW_LOGS.value],
            authRequired: true,
          },
        },
        // TRASH
        {
          path: '/trash',
          name: 'trash',
          component: () => import('@/views/pages/trash/Trash'),
          meta: {
            title: 'Trash',
            permissions: [UserPermission.VIEW_TRASH.value],
            authRequired: true,
          },
        },

        // AVAILABILITY
        {
          path: '/availability',
          name: 'candidate-availability',
          component: () => import('@/views/pages/availability/Availability'),
          meta: {
            title: 'Availability',
            role: UserRole.CANDIDATE.value,
            authRequired: true,
          },
        },

        // CV-GENERATOR
        {
          path: '/cv-generator',
          name: 'cv-generator',
          component: () => import('@/views/pages/cv-generator/cv-list/CVList'),
          meta: {
            title: 'CV Generator',
            permissions: [UserPermission.VIEW_CVS.value],
            authRequired: true,
          },
        },
        {
          path: '/cv-generator/create',
          name: 'cv-create',
          component: () => import('@/views/pages/cv-generator/cv-manager/CVManager'),
          meta: {
            title: 'Create CV',
            permissions: [UserPermission.CREATE_CVS.value],
            authRequired: true,
          },
        },
        {
          path: '/cv-generator/edit/:id',
          name: 'cv-edit',
          component: () => import('@/views/pages/cv-generator/cv-manager/CVManager'),
          meta: {
            title: 'Edit CV',
            permissions: [UserPermission.EDIT_CVS.value],
            authRequired: true,
          },
        },

        // JOB OFFERS
        {
          path: '/jobs',
          name: 'jobs-list',
          component: () => import('@/views/pages/jobs/JobList'),
          meta: {
            title: 'Job offers',
            permissions: [UserPermission.VIEW_JOBS.value],
            authRequired: true,
          },
        },
        {
          path: '/jobs/create',
          name: 'jobs-create',
          component: () => import('@/views/pages/jobs/JobCreate'),
          meta: {
            title: 'Create job offer',
            permissions: [UserPermission.CREATE_JOBS.value],
            authRequired: true,
          },
        },
        {
          path: '/jobs/edit/:id',
          name: 'jobs-edit',
          component: () => import('@/views/pages/jobs/JobEdit'),
          meta: {
            title: 'Edit job offer',
            permissions: [UserPermission.EDIT_JOBS.value],
            authRequired: true,
          },
        },

        // SETTINGS
        {
          path: '/settings',
          name: 'settings',
          component: () => import('@/views/pages/settings/Settings'),
          meta: {
            title: 'Settings',
            permissions: [UserPermission.VIEW_SETTINGS.value],
            authRequired: true,
          },
        },
        {
          path: '/settings/skill-groups/create',
          name: 'settings-skill-groups-create',
          component: () => import('@/views/pages/settings/skill-groups/SkillGroupsCreate'),
          meta: {
            title: 'Create Skill Groups',
            permissions: [UserPermission.CREATE_SETTINGS.value],
            authRequired: true,
          },
        },
        {
          path: '/settings/skill-groups/edit/:id',
          name: 'settings-skill-groups-edit',
          component: () => import('@/views/pages/settings/skill-groups/SkillGroupsEdit'),
          meta: {
            title: 'Edit Skill Groups',
            permissions: [UserPermission.EDIT_SETTINGS.value],
            authRequired: true,
          },
        },

        // MAILBOX
        {
          path: '/mailbox',
          name: 'mailbox',
          component: () => import('@/views/pages/mailbox/Mailbox'),
          meta: {
            title: 'Mailbox',
            permissions: [UserPermission.VIEW_MAILBOX.value],
            authRequired: true,
          },
        },

        // CANDIDATE
        {
          path: '/my-profile',
          name: 'my-profiles',
          component: () => import('@/views/pages/candidate/MyProfile'),
          meta: {
            title: 'My Profile',
            permissions: [UserPermission.VIEW_PROFILES.value],
            authRequired: true,
          },
        },

        // APPLICATION FORMS
        {
          path: '/application-forms',
          name: 'application-forms',
          component: () => import('@/views/pages/application-forms/ApplicationForms'),
          meta: {
            title: 'Application Forms',
            permissions: [UserPermission.VIEW_APPLICATION_FORMS.value],
            authRequired: true,
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login'),
          meta: {
            title: 'Login',
            fullPage: true,
          },
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/pages/register/Register'),
          meta: {
            title: 'Register',
            fullPage: true,
          },
        },
        {
          path: '/register/candidate',
          name: 'register-candidate',
          component: () => import('@/views/pages/register/RegisterCandidate'),
          meta: {
            title: 'Register candidate',
            fullPage: true,
          },
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import('@/views/pages/ForgotPassword'),
          meta: {
            title: 'Forgot password',
            fullPage: true,
          },
        },
        {
          path: '/reset-password',
          name: 'reset-password',
          component: () => import('@/views/pages/ResetPassword'),
          meta: {
            title: 'Reset password',
            fullPage: true,
          },
        },
        {
          path: '/verify',
          name: 'verify',
          component: () => import('@/views/pages/Verify'),
          meta: {
            title: 'Verify',
            fullPage: true,
          },
        },
        {
          path: '/verify/resend',
          name: 'verify-resend',
          component: () => import('@/views/pages/VerifyResend'),
          meta: {
            title: 'Verify resend',
            fullPage: true,
          },
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404'),
          meta: {
            title: '404',
          },
        },
        {
          path: '/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized'),
          meta: {
            title: '401',
          },
        },
      ],
    },
    {
      path: '*',
      redirect: '/error-404',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const isAuth = auth.isAuthenticated;
  const { permissions } = to.meta;
  const hasPermissions = !!permissions;

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    if (!isAuth) {
      const accessTokenExists = localStorage.getItem('accessToken');

      if (!accessTokenExists) {
        next({ name: 'login', query: { to: to.path } });
        return;
      }

      await jwt
        .refreshToken()
        .then((response) => {
          const { access_token, expires_in } = response.data;

          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('tokenExpiry', Date.now() + expires_in * 1000);

          next();
        })
        .catch((error) => {
          console.error(error);
          next({ name: 'login', query: { to: to.path } });
        });
    }
  }

  // If user logins first time
  if (isAuth && auth.isFirstLogin() && to.name !== 'profile') {
    next({ name: 'profile' });
  }

  // It user has no permission to go to a specific route
  if (isAuth && hasPermissions && !gate.can(permissions) && to.name !== 'page-not-authorized') {
    next({ name: 'page-not-authorized' });
  }

  // If full page
  if (to.meta.fullPage && auth.isExpiryValid) {
    next({ path: '/' });
  }

  next();
});

router.afterEach((to) => {
  disableLoader();
  setPageTitle(to);
});

export default router;
