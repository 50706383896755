import { UserPermission } from '@/enums/UserPermission';
import { UserRole } from '@/enums/UserRole';

const userInfoKey = 'userInfo';
const tokenExpiryKey = 'tokenExpiry';

class AuthService {
  get isAuthenticated() {
    const { isExpiryValid, isUserValid } = this;
    const isAuthenticated = isExpiryValid && isUserValid;

    return isAuthenticated;
  }

  get isExpiryValid() {
    const now = new Date(Date.now());
    const tokenExpiry = new Date(parseInt(localStorage.getItem(tokenExpiryKey), 10));
    const isExpired = now < tokenExpiry;

    return isExpired;
  }

  get isUserValid() {
    return Boolean(localStorage.getItem(userInfoKey)) && Boolean(JSON.parse(localStorage.getItem('userInfo')).id);
  }

  getUser() {
    return JSON.parse(localStorage.getItem(userInfoKey));
  }

  isFirstLogin() {
    return this.getUser() ? this.getUser().is_first_login : false;
  }

  getUserRole() {
    return this.getUser() ? this.getUser().role : null;
  }

  getUserPermissions() {
    return this.getUser() ? this.getUser().permission_names : null;
  }

  getDefaultLoginRoute() {
    const permissions = this.getUserPermissions();
    const role = this.getUserRole();

    if (!this.getUser()) {
      return '/login';
    }

    if (permissions.includes(UserPermission.VIEW_MAILBOX.value)) {
      return '/mailbox';
    }

    if (permissions.includes(UserPermission.VIEW_CANDIDATES.value)) {
      return '/candidates';
    }

    if (permissions.includes(UserPermission.VIEW_APPLICATION_FORMS.value)) {
      return '/application-forms';
    }

    if (permissions.includes(UserPermission.VIEW_CVS.value)) {
      return '/cv-generator';
    }

    if (permissions.includes(UserPermission.VIEW_JOBS.value)) {
      return '/jobs';
    }

    // if (permissions.includes(UserPermission.VIEW_RECRUITERS.value)) {
    //   return '/recruiters';
    // }

    // if (permissions.includes(UserPermission.VIEW_TEAMS.value)) {
    //   return '/teams';
    // }

    if (permissions.includes(UserPermission.VIEW_USERS.value)) {
      return '/users';
    }

    // if (permissions.includes(UserPermission.VIEW_LOGS.value)) {
    //   return '/logs';
    // }

    // if (permissions.includes(UserPermission.VIEW_TRASH.value)) {
    //   return '/trash';
    // }

    if (role === UserRole.CANDIDATE.value) {
      return '/mailbox';
    }

    return '/user-profile';
  }
}

export default new AuthService();
